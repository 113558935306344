


const fetchData = async(url:string,method:string='get',data:any,token:string,headers_type:null|string)=>{

    let response = null, responseCode=null
  
   
    let headers = {
      'Content-Type': 'application/json',
      'Authorization':  "Bearer " +token,
    }
    let options:any
    // let options={
    //     'method':'get',
    //     headers,
    //     //body:
    // }
    // if(token){
    //   headers = {
    //     'Authorization':  'bearer'+token
    //   }
    // }
//     if (headers_type === 'json') {
//       headers['Content-Type'] =  'application/json'
//   }
//  else 
  if(headers_type ==='multi'){
    headers['Content-Type'] = 'multipart/form-data' 
  }
  
    if(method.toLowerCase()==='get'){
      //  options['method'] = 'GET'
      options={
        method:'GET',
        headers,
      }
    }else if(method.toLowerCase()==='post'){
        // options['method'] = 'POST'
        // options['body'] = JSON.stringify({data})
      options={
        method:'POST',
        headers,
        body:JSON.stringify({data}),
       
      }
    }else if(method.toLowerCase()==='delete'){
       // options['method'] = 'DELETE'
      options={
        method:'DELETE',
        headers
      }
    }
    try {
      response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/${url}`,options).then((res)=>{
        responseCode= res.status
        if (res.status === 401) {
          throw new Error("401")
      }
      else if (res.status === 403) {
          throw new Error("403")
      }
      else if (res.status === 422) {
          return res.json()
      
      }
      else if (res.status === 200) {
  
          return res.json()
      }
      })
  
    } catch (error) {
      response = null
    }finally{
      return {
        response: response,
        responseCode: responseCode
    }
    }
  }
  
  export default fetchData
  