import React, { Fragment, useState } from 'react'
import { Dropdown, Pagination, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { Button } from 'react-bootstrap'
import { useAuth } from 'react-oidc-context'
import { ToastContainer, toast } from 'react-toastify'
import fetchData from './fetchdata'

interface ModalDataType{
  isOpen:boolean,
  data:any|null,
  linkdata:RouteLinkType | null,
  index:number,
  //routeLink:RouteLinkType
}

interface RouteLinkType{
  type: number,
  link: string,
  linkedField: string,
  caption: string,
  permission_field: string,
  subField?:string,
  dividerAfter:boolean
}

interface TableDataType{
  [key:string]:string|number|boolean
}

function CustomTable(props: any) {
  const auth = useAuth()
  const {headings, generateRows, data} = props

  const [modalData, setModalData] = useState<ModalDataType>({
    isOpen: false,
    data: null,
    linkdata: null,
    index: 0,
  });


  const handleOnSubmit = async () => {
    if(modalData.linkdata){
      let url = `${modalData.linkdata['link']}/${modalData.data[modalData.index][modalData.linkdata['linkedField']]}`;
      if(modalData.linkdata && modalData.linkdata.subField){
        url=`${modalData.linkdata['link']}/${modalData.data[modalData.index][modalData.linkdata['subField']][modalData.linkdata['linkedField']]}`
      }

      const result = await fetchData(url,'delete',null,String(auth.user?.id_token),'json')

      if (result.responseCode===200) {
        props.setData((prevState: TableDataType[]) =>(
          prevState.filter((_: any, index: number) => index !== modalData.index)
         )
         );
        handleOnClose();
        toast.success("Data deleted successfully", {
          position: "bottom-right",
        });
      } else {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
      handleOnClose();
    }

  };

  const handleDeleteShow = ( data: null, linkdata: RouteLinkType, index: number) => {
    modalData.isOpen = true;
    modalData.data = data;
    modalData.linkdata = linkdata;
    modalData.index = index;
    setModalData({ ...modalData });
  };

  const handleOnClose = () => {
    modalData.isOpen = false;
    setModalData({ ...modalData });
  };
  return (
<div className='table-responsive'>
<ToastContainer/>
      <Table striped bordered hover>
        <thead>
          <tr>
            {headings.map((theading:string, index:number) => (
              <th key={index} scope='col'>
                {theading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length===0 && <tr>
                <td className="text-center" colSpan={headings.length}>
                  No Data Found
                </td>
              </tr>}
          {data && data.length>0 && data.map((tableItem:any, index:number)=>

            <tr key={index}>
              {generateRows(tableItem)}
              {props.routeLinks.length > 0 &&
            <td>
              <Dropdown>
                <Dropdown.Toggle
                  variant='light'
                  size='sm'
                  id='dropdown-basic'
                >
                  Actions
                </Dropdown.Toggle>

                <Dropdown.Menu variant='light'>
                  {props.routeLinks.map((routeLink:RouteLinkType, linkIndex:number) => (
                    <Fragment key={linkIndex}>
                      {routeLink.type !== 3 &&
                        (
                          <Link
                            to={routeLink['subField']?`${routeLink['link']}/${tableItem[routeLink['subField']][routeLink['linkedField']] //tableItem.module.id
                              }`:`${routeLink['link']}/${tableItem[routeLink['linkedField']]}`}
                          >
                            <Dropdown.Item as={'span'}>
                              {routeLink['caption']}
                            </Dropdown.Item>
                            {routeLink.dividerAfter && <Dropdown.Divider />}
                          </Link>
                        )}
                         {routeLink.type === 3 &&
                        (
                          <div onClick={(e) => handleDeleteShow( data, routeLink, index)} style={{cursor:'pointer'}}
                          >
                            <Dropdown.Item as={'span'}>
                              {routeLink['caption']}
                            </Dropdown.Item>
                          </div>
                        )}
                    </Fragment>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </td>
          }
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={modalData.isOpen} onHide={handleOnClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete this data?</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleOnClose}>
            Close
          </Button>
          <Button variant="danger" onClick={handleOnSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default CustomTable