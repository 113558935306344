import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const styles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40vh',
  backgroundColor: '#f8f9fa',
  color: '#333',
  textAlign: 'center' as 'center',
};

const titleStyles: React.CSSProperties = {
  fontSize: '2rem',
  margin: '20px 0',
};

const messageStyles: React.CSSProperties = {
  fontSize: '1rem',
  margin:'5px'
};

function AccessDenied({url}:{url:string}) {
  const history = useHistory()
  return (
    <div style={styles}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="red"
        width="100px"
        height="100px"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm3 15-6-6 1.41-1.41L12 13.17l3.59-3.59L17 11l-5 5zm-3-7h2v5h-2zm0-2h2v1h-2V8z" />
      </svg>
      <h1 style={titleStyles}>Access Denied</h1>
      <p style={messageStyles}>You do not have permission to view this page.</p>
       <Button onClick={()=>history.push(url)}>Go Back</Button>
    </div>
  );
}

export default AccessDenied