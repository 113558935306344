import { useState, useEffect } from "react";
import { Button, Card, Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import fetchData from "../../helper/fetchdata";
import { ToastContainer, toast } from "react-toastify";
import "../../style/index.css";
import { useProjects } from "../../context/ProjectContext";
import GoHome from "./GoHome";

interface ModuleListType {
  project: {
    name: string;
    id: string;
  };
  module_id: number;
  module_name: string;
  module_api_url: string;
}



function ProjectPage() {
  const auth = useAuth();
  const history = useHistory();
  // const data = useProjects();
  const params: { [key: string]: string | undefined } = useParams();
  const [moduleData, setModuleData] = useState<ModuleListType[]>([]);
  const [initialLoading, setInitialLoading] = useState(false);

  useEffect(() => {
    if (params.id) {
      getData(params.id);
    }
  }, []);

  //get the project data that the user has access to
  const getData = async (id: string) => {
    setInitialLoading(true);
    try {
      const result = await fetchData(
        `modules/project-id/${id}`,
        "get",
        null,
        String(auth.user?.id_token),
        "json"
      );

      if (result.responseCode === 200) {
        setModuleData(result.response.data);
      } else {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
    setInitialLoading(false);
  };

  return (
    <div
      className="container"
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <ToastContainer />
      {initialLoading ? (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : moduleData && moduleData.length > 0 ? (
        moduleData.map((moduleItem, index) => (
          <Card
            key={index}
            style={{ width: "25rem", margin: "1%" }}
            onClick={() => history.push(`/module/id/${moduleItem.module_id}`)}
          >
            <Card.Body>
              <Card.Title>{moduleItem.module_name}</Card.Title>
              <Button>View {moduleItem.module_name}</Button>
            </Card.Body>
          </Card>
        ))
      ) : (
       <GoHome name={'Module'}/>
      )}
    </div>
  );
}

export default ProjectPage;
