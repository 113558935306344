import { useState, useEffect } from "react";
import "../../style/index.css";
import { Card, Button, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import fetchData from "../../helper/fetchdata";
import { ToastContainer, toast } from "react-toastify";
import { ProjectProvider, useProjects } from "../../context/ProjectContext";
import GoHome from "./GoHome";

interface TabListType {
  project_id: number;
  project_name: string;
  project_url?: string;
  project_description: string;
}

function Home() {
  const history = useHistory();
  const data = useProjects();

  // console.log("data is in home: ", data);

  const navigateRoute = (item_name: TabListType) => {
    let path = `/project/${item_name.project_id}`;
    if (item_name.project_id === 1) {
      path = `/admin`;
    }
    history.push(path);
  };

  return (
    <div
      className="container"
      style={{
        marginTop: "5%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <ToastContainer />
      {data?.initialLoading===3 ? (
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : data?.tabList && data?.tabList.length > 0 ? (
        data?.tabList.map(
          (tabItem: TabListType, index: number) =>
            tabItem.project_id !== 2 && (
              <Card key={index} style={{ width: "25rem", margin: "1%" }}>
                <Card.Body>
                  <Card.Title>{tabItem.project_name}</Card.Title>
                  <Card.Text>{tabItem.project_description}</Card.Text>
                  <Button
                    variant="primary"
                    onClick={() => navigateRoute(tabItem)}
                  >
                    Go {tabItem.project_name}
                  </Button>
                </Card.Body>
              </Card>
            )
        )
      ) : (
        <GoHome name={'Project'} isHome={true}/>
      )}
      {data?.initialLoading===1 && data?.tabList && data?.tabList.length === 1  && data?.isPermission &&
      <Card style={{ width: "25rem", margin: "1%" }}>
                <Card.Body>

                  <Card.Title>You only have Permission Access</Card.Title>
                  <Button
                    variant="primary"
                    onClick={() => history.push('/admin/permission')}
                  >
                    Go Permission
                  </Button>
                </Card.Body>
        </Card>
     }
    </div>
  );
}

export default Home;
