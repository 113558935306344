import { Button, Card, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useProjects } from '../../context/ProjectContext';
import AccessDenied from '../Pages/AccessDenied';
import { useEffect, useState } from 'react';

function Admin() {
  const history = useHistory();
  const data = useProjects();
    const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {
    // console.log(data)

if(data?.initialLoading===1 && !data?.isSettings && !data?.isPermission){
  setAccessDenied(1)
}

  }, [data?.initialLoading])


  return (
    <div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          marginTop: "3%",
          justifyContent: "center",
        }}
      >
        {data?.initialLoading===3 && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
           {data?.initialLoading===1 && data?.isSettings &&<Card style={{ width: "25rem", margin: "1%" }}>
              <Card.Body>
                <Card.Title>
                  Projects
                </Card.Title>
                <Card.Text></Card.Text>
                <Button
                  variant="primary"
                  onClick={() => history.push('admin/project')}
                >
                  Go Project
                </Button>
              </Card.Body>
            </Card>}
            {data?.initialLoading===1 && data?.isPermission &&
                <Card style={{ width: "25rem", margin: "1%" }}>
              <Card.Body>
                <Card.Title>
                  Permissions
                </Card.Title>
                <Card.Text></Card.Text>
                <Button
                  variant="primary"
                  onClick={() => history.push('admin/permission')}
                >
                  Go Permission
                </Button>
              </Card.Body>
            </Card>}
            {data?.initialLoading===1 && accessDenied===1 && <AccessDenied url={"/home"}/>}
      </div>
    </div>
  );
}

export default Admin;


