import { useState, useEffect, useRef, FormEvent } from 'react'
import {  useParams,useHistory } from 'react-router-dom'
import { Button,Form, Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../helper/fetchdata';
import '../../../style/index.css'
import { useProjects } from '../../../context/ProjectContext';
import AccessDenied from '../../Pages/AccessDenied';

interface ProjectType{
  project_id:number;
  project_name: string;
  project_description: string ;
  project_url: string;
}

function AddProject() {
    const auth = useAuth()
    const formRef = useRef(null)
    const data = useProjects();
    const params:{[key:string]:string|undefined} = useParams()
    const history = useHistory()
    const [projectData, setProjectData] = useState<ProjectType>()
    const [initialLoading, setInitialLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [accessDenied, setAccessDenied] = useState(3)

    useEffect(() => {
      if(params.id && data?.initialLoading===1 && data?.isSettings){
          getData(params.id)
      }
      //console.log(data)
      if(data?.initialLoading===1 && !data?.isSettings){
         setAccessDenied(1)
          setInitialLoading(false)
      }
      if(data?.initialLoading===1 && data?.isSettings){
         setAccessDenied(2)
          setInitialLoading(false)
      }
      if(data?.initialLoading === 3){
        setInitialLoading(true)
      }
    }, [data?.initialLoading])

    const getData = async(id:string)=>{
      setInitialLoading(true)
      try {
        const result = await fetchData(`admin/projects/id/${id}`,'get',null,String(auth.user?.id_token),'')
        if(result.responseCode===200){
               setProjectData(result.response.data[0])
              setInitialLoading(false)
        }else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      } else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
      setInitialLoading(false)
    }

//submit project data for both add and edit page
const submitForm = async(e: FormEvent<HTMLFormElement>)=>{
  try {
    e.preventDefault()
    setButtonLoading(true)
    if(formRef.current){
      const formData = new FormData(formRef.current)
     let data:{[key:string]:any} = {};
      formData.forEach(function(value, key){
        data[key] = value;
      });

      let url:string=`admin/projects/add-project`
      if(params.id){
        url = `admin/projects/edit-project/${params.id}`
      }
      const result = await fetchData(url,'post',data,String(auth.user?.id_token),'json')
      if(result.responseCode===200){
            history.push('/admin/project')
            setButtonLoading(false)
      }else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      } else{
          toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    }
  } catch (error) {
    toast.error("Something went wrong", {
      position: "bottom-right",
    });
  }
}

  return (
    <>
    {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
    {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading && <div className="container" style={{ marginTop: "2%" }}>
        <div
    style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <h2 style={{ fontSize: "2em", margin: "10px 0", fontWeight: "bold" }}>
      {params.id ? "Edit" : "Add"} Project
    </h2>
    <Button onClick={() => history.push(`/admin/project`)}>Go Back</Button>
  </div>
  <ToastContainer />
  <Form ref={formRef} onSubmit={(e) => submitForm(e)}>
      <Form.Group
        className="mb-3 row ml-4"
        controlId="exampleForm.ControlInput1"
      >
        <Form.Label className="col-sm-2 col-form-label">
          Project Name
        </Form.Label>
        <div className="col-sm-10">
          <Form.Control
            type="text"
            name="name"
            defaultValue={
              params.id && projectData ? projectData.project_name : ""
            }
            required
          />
        </div>
      </Form.Group>
      <Form.Group
        className="mb-3 row ml-4"
        controlId="exampleForm.ControlInput1"
      >
        <Form.Label className="col-sm-2 col-form-label">
          Project Description
        </Form.Label>
        <div className="col-sm-10">
          <Form.Control
            as="textarea"
            rows={3}
            name="description"
            defaultValue={
              params.id && projectData ? projectData.project_description : ""
            }
            required
          />
        </div>
      </Form.Group>
      <Form.Group
        className="mb-3 row ml-4"
        controlId="exampleForm.ControlInput1"
      >
        <Form.Label className="col-sm-2 col-form-label">Project URL</Form.Label>
        <div className="col-sm-10">
          <Form.Control
            type="text"
            name="url"
            defaultValue={
              params.id && projectData ? projectData.project_url : ""
            }
            required
          />
        </div>
      </Form.Group>
      <Button variant="primary" type="submit">
        {buttonLoading ? (
          <Spinner animation="border" role="status" size="sm">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          "Submit"
        )}
      </Button>
    </Form>

      </div>}

    </>


  )
}

export default AddProject