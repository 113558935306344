import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import CustomTable from '../../../helper/CustomTable'
import { Button, Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../helper/fetchdata';
import '../../../style/index.css'
import { useProjects } from '../../../context/ProjectContext';
import AccessDenied from '../../Pages/AccessDenied';

interface ProjectType{
  project_id:number;
  project_name:string;
  project_url:string;
}

const AllProjects=()=>{
  const auth = useAuth()
  const history = useHistory()
  const data = useProjects();
  const [initialLoading, setInitialLoading] = useState<Boolean>(true)
  const [dataList, setDataList] = useState<ProjectType[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {
    //console.log(data)
if(data?.initialLoading===1 && data?.isSettings){
   getData()
}
if(data?.initialLoading===1 && !data?.isSettings){
  setAccessDenied(1)
  setInitialLoading(false)
}

  }, [data?.initialLoading])

  //getting all data for project
  const getData = async()=>{
    try {
      setInitialLoading(true)
      const result = await fetchData(`admin/projects?pageno=${currentPage}`,'post',null,String(auth.user?.id_token),'json')
      if(result.responseCode===200){
            setDataList(result.response.data)
            setAccessDenied(2)
            setInitialLoading(false)
      }else if(result.responseCode===403){
          setAccessDenied(1)
          setInitialLoading(false)
        }else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
           //setInitialLoading(false)
        }

    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });

    }

  }

  const headings = ['Name','Project URL','Actions']

  const actionOption = [ {
    type: 1,
    link: "/admin/module/project-id",
    linkedField: "project_id",
    caption: "View Module",
    permission_field: "perm_view"
  },{
    type: 2,
    link: "/admin/project/edit",
    linkedField: "project_id",
    caption: "Edit Project",
    permission_field: "perm_update"
},{
  type: 3,
  link: "admin/projects/delete/id",
  linkedField: "project_id",
  caption: "Delete Project",
  permission_field: "perm_delete"
}]

  const generateRows= (data:ProjectType)=>{
    return(
      <>
        <td>{data.project_name}</td>
        <td>{data.project_url}</td>
      </>
    )
  }
  return (
    <div className='m-5'>
       {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading && <>
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2%' }}>
    <div>
      <Button variant='primary' onClick={()=>history.push('/admin/project/add')}>Add Projects</Button>
    </div>
    <div>
      <Button onClick={()=>history.push('/admin')}>Go Back</Button>
    </div>
  </div>
   <ToastContainer />
    <CustomTable headings={headings} generateRows={generateRows} data={dataList} setData={setDataList} routeLinks={actionOption}/>
      </>}
    </div>
  )
}

export default AllProjects