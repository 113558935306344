import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import CustomTable from '../../../helper/CustomTable'
import { Button, Spinner } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchData from '../../../helper/fetchdata';
import '../../../style/index.css'
import AccessDenied from '../../Pages/AccessDenied';
import { useProjects } from '../../../context/ProjectContext';

interface PermissionType{
  user:{
    id:number,
    name:string
  },
  project:{
    id:number,
    name:string
  },
  module:ModulePermissionDataType[]
  }
  interface ModulePermissionDataType{
    id:number,
    name:string
  }

function AllPermission() {
    const auth = useAuth()
    const history = useHistory()
    const data = useProjects();
    const [initialLoading, setInitialLoading] = useState<Boolean>(true)
    const [dataList, setDataList] = useState<PermissionType[]>([])
    const [accessDenied, setAccessDenied] = useState(3)


  useEffect(() => {
    //console.log(data)
if(data?.initialLoading===1 && data?.isSettings){
   getData()
}
if(data?.initialLoading===1 && !data?.isSettings){
  setAccessDenied(1)
  setInitialLoading(false)
}

  }, [data?.initialLoading])

    //get all the permission data
    const getData = async()=>{
      setInitialLoading(true)
      try {
        const result = await fetchData(`admin/permissions`,'get',null,String(auth.user?.id_token),'json')
        if(result.responseCode===200){
              setDataList(result.response.data)
              setAccessDenied(2)
              setInitialLoading(false)
        }else if(result.responseCode===403){

          // setInitialLoading(true)
          // toast.error("Access Denied", {
          //   position: "bottom-right",
          // });
          setAccessDenied(1)
          setInitialLoading(false)
        }else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
        }
      } catch (error) {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    }

    const headings = ['User Name','Project Name','Module Name','Actions']

    const actionOption = [
      {
      type: 2,
      link: "/admin/permission/edit",
      linkedField: "id",
      caption: "Edit Permission",
      permission_field: "perm_update"
      },
      {
    type: 3,
    link: "admin/permissions/delete",
    linkedField: "id",
    caption: "Delete Permission",
    permission_field: "perm_delete"
  }
]

  const moduleReformattedData=(data:ModulePermissionDataType[])=>{
    return data.filter(item=>item).map(item=>item.name).join(', ')
  }

    const generateRows= (data:PermissionType)=>{
      return(
        <>
          <td>{data.user?.name}</td>
          <td>{data.project?.name}</td>
          <td>{moduleReformattedData(data.module)}</td>

        </>
      )
    }
    return (

       <div className='m-5'>
       {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading && <>
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2%' }}>
    <div>
      <Button variant='primary' onClick={()=>history.push('/admin/permission/add')}>Add Permission</Button>
    </div>
    <div>
      <Button onClick={()=>history.push('/admin')}>Go Back</Button>
    </div>
  </div>
   <ToastContainer />
    <CustomTable headings={headings} generateRows={generateRows} data={dataList} setData={setDataList} routeLinks={actionOption}/>
      </>}
    </div>
    )
  }

export default AllPermission