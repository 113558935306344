import { Navbar, Container, Button, Nav } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useProjects } from '../context/ProjectContext';

export const Navigation = () => {
  const auth = useAuth()
  const data = useProjects()

  // console.log("data in nav bar: ", data)

  return (

    <Navbar className="bg-dark text-light">

    <Container>
    <img className="stockedgelogo" style={{ "width": "60px",
    "height": "auto", marginRight:'20px'}} src="https://duzycfafl38re.cloudfront.net/Website/Images/stockedgelogoimage28102022144458.png" width="60" height="60" alt="StockEdge"></img>
      <Navbar.Brand href="#home" className="text-light">

      </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto text-light">
            <Nav.Link className="text-light" href="/home">Home</Nav.Link>
            <Nav.Link className="text-light" href="/admin/permission">Permission</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav className="me-auto">{auth.isAuthenticated ? <Button variant="danger" onClick={()=>auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })}>Logout</Button>:''}</Nav>
    </Container>
  </Navbar>
  );
};
