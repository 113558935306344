import React from 'react';
import { Container } from 'react-bootstrap';

function Footer() {
  return (
    <footer style={styles.footer}>
      <Container>
        <div style={styles.footerContent}>
          <p>&copy; Copyright 2024 © Kredent InfoEdge Pvt. Ltd.</p>
          <p>
            <a href="/privacy" style={styles.link}>Privacy Policy</a> |
            <a href="/terms" style={styles.link}> Terms of Service</a>
          </p>
        </div>
      </Container>
    </footer>
  );
}

const styles:any = {
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    width: '100%',
    padding: '10px 0',
    textAlign: 'center' ,
    position: 'fixed',
    bottom: '0',
  },
  footerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    marginLeft: '10px',
  },
};

export default Footer;
