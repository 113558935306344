import './style/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import App from './App';


const oAuthDomain = process.env.REACT_APP_OAUTH_DOMAIN as string

const oidcConfig: AuthProviderProps = {
  authority: oAuthDomain,
	client_id: process.env.REACT_APP_OAUTH_CLIENT_ID as string,
	redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}/login-success`,
  scope: process.env.REACT_APP_ALLOWED_SCOPES as string,
};

ReactDOM.render(
  <React.StrictMode>
    		<AuthProvider {...oidcConfig}>
        <App />
	</AuthProvider>

  </React.StrictMode>,
  document.getElementById('root'),
);


