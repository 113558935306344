import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { useHistory} from 'react-router-dom';
import fetchData from '../helper/fetchdata';
import { Spinner } from 'react-bootstrap';
import '../style/index.css'


function LoginSuccess() {
  const auth = useAuth()
  const history = useHistory()


  useEffect(() => {
     //console.log(auth)
    if(auth.user){
      login()
    }
  }, [auth.user])


  const login = async() => {
      const data = {token: auth.user?.access_token}
      const result = await fetchData('users/login','post',data,String(auth.user?.id_token),'json')
      if(result.responseCode === 200){
        history.push('/home')
      }else{
        auth.signoutRedirect({ 'post_logout_redirect_uri': `${process.env.REACT_APP_REDIRECT_URI}/login` })

      }
    }

    return (
      <div>
         {!auth.isAuthenticated && auth.isLoading &&
          <div className="spinner">
            <Spinner animation="border" role="status"/>
          </div>
          }
        </div>
    )
}

export default LoginSuccess