
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '@stoplight/elements/styles.min.css';
import { API } from '@stoplight/elements';
import { useAuth } from 'react-oidc-context';
import { Spinner, ToastContainer } from 'react-bootstrap';
import '../../style/index.css'
import AccessDenied from './AccessDenied';
import fetchData from '../../helper/fetchdata';
import { toast } from 'react-toastify';

interface UrlDataType{module_name:string,module_api_url:string}

const StoplightAPI = () => {
  const auth = useAuth()
  const params: {[key:string]:string|undefined}= useParams()
  const [urlData, setUrlData] = useState<UrlDataType>({module_name:'',module_api_url:''})
  const [initialLoading, setInitialLoading] = useState(false)
  const [accessDenied, setAccessDenied] = useState(3)

  useEffect(() => {
    //console.log(data)
    getData()
   }, [])

   const getData = async()=>{
    setInitialLoading(true)

const result = await fetchData(`admin/modules/get-api-url/id/${params.id}`,'get',null,String(auth.user?.id_token),'json')

     //console.log(result)
      if(result.responseCode===200){
            // setDataList(result.response.data)
            urlData.module_api_url = result.response.data[0].module_api_url
            urlData.module_name = result.response.data[0].module_name
            setUrlData({...urlData})
            setAccessDenied(2)
            setInitialLoading(false)
      }else if(result.responseCode===403){
          setAccessDenied(1)
          setInitialLoading(false)
        }
        else{
            toast.error("Something went wrong", {
            position: "bottom-right",
          });
           setInitialLoading(false)
        }
   }

  return (
    <>
    {accessDenied===1 && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>}
    {accessDenied===2 && !initialLoading && <>
     <ToastContainer/>
    <API
    basePath={`/module/id/${params.id}`}
     apiDescriptionUrl={urlData.module_api_url}
  />
  </>}
  </>
  )
};

export default StoplightAPI


