import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import  fetchData  from "../helper/fetchdata";
import { ToastContainer, toast } from "react-toastify";
import { useAuth } from 'react-oidc-context';

interface TabListType {
  project_id: number;
  project_name: string;
  project_url?: string;
  project_description: string;
}

interface ProjectContextProps {
  tabList: TabListType[];
  isPermission: boolean;
  isSettings: boolean;
  initialLoading: number;
  getData: () => void;
}


interface ProjectProviderProps {
  children: ReactNode;
}
const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

export const ProjectProvider: React.FC<ProjectProviderProps> = ({ children }) => {
  const [tabList, setTabList] = useState<TabListType[]>([]);
  const [initialLoading, setInitialLoading] = useState(3);
  const [isPermission, setIsPermission] = useState(false)
  const [isSettings, setIsSettings] = useState(false)
  const auth = useAuth()

  const getData = async () => {
    // console.log("inside get data")
    // setInitialLoading(true);
    setInitialLoading(2);
    try {
      const result = await fetchData('projects', 'get', null, String(auth.user?.id_token), 'json');
      if (result.responseCode === 200) {
       // console.log("response is: ",result.response)
        setTabList(result.response.data);
       if(result.response.data){
        setIsPermission(result.response.data.some((item:any)=>item.project_id===2))
        let settingExist= result.response.data.some((item:any)=>item.project_id===1)
        setIsSettings(settingExist)
       }
      } else {
        toast.error("Something went wrong", {
          position: "bottom-right",
        });
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
    // setInitialLoading(false);
    setInitialLoading(1);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ProjectContext.Provider value={{ tabList, isPermission, isSettings, initialLoading, getData }}>
      {children}
      <ToastContainer />
    </ProjectContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectContext);
  // console.log("context is: ",context)
  if (context === undefined) {
    return null
    // throw new Error("No Project");
  }
  return context;
};
