import { useAuth } from 'react-oidc-context'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import {Redirect} from 'react-router-dom'
import image from '../../public/images/10b83d63-b05c-4e4b-b18d-942e5804e856.jpeg'

function Login() {
  const auth = useAuth();

  return (
<Container fluid style={styles.container}>
      <Row className="align-items-center justify-content-center" style={styles.row}>
        <Col md={7}>
          <div>
            <Card.Body>
              <main style={styles.main}>
                <h2 style={styles.title}>Welcome to the Documentation Portal</h2>
                <p style={styles.message}>
                  This portal provides access to documentation for all of our company's products. Depending on your project involvement and access level, you can view, edit, update, or manage documentation for various projects.
                </p>
                <section style={styles.section}>
                  <h3 style={styles.sectionTitle}>Getting Started</h3>
                  <ol style={styles.list}>
                    <li><b>Sign In</b>: Use your company credentials to log in and access your assigned projects.</li>
                    <li><b>Access Documentation</b>: Navigate to the documentation section of your assigned projects.</li>
                    <li><b>Manage Access</b>: Depending on your role, you may be able to view, edit, or manage documentation.</li>
                  </ol>
                </section>
                <section style={styles.section}>
                  <h3 style={styles.sectionTitle}>Key Features</h3>
                  <ul style={styles.list}>
                    <li><b>Role-Based Access</b>: View or manage documentation based on your access level.</li>
                    <li><b>Comprehensive Guides</b>: Detailed documentation to help you understand and use our APIs effectively.</li>
                    <li><b>Collaborative Tools</b>: Work with team members to edit and update documentation.</li>
                  </ul>
                </section>
                <section style={styles.section}>
                  <h3 style={styles.sectionTitle}>Support</h3>
                  <p>
                    For additional help, contact us at <a style={{fontWeight:'bold'}}href="mailto:sulagna@stockdge.com">sulagna@stockdege.com</a>.
                  </p>
                </section>

                <p>
                  Thank you for using our Documentation Portal! We're excited to support your project.
                </p>
                {auth.isAuthenticated && !auth.isLoading && <Redirect to="/home" />}
                <Button className="d-grid gap-2" size="lg" style={styles.button} onClick={() => auth.signinRedirect()}>
                  Login
                </Button>
              </main>
            </Card.Body>
          </div>
        </Col>
        <Col md={4}>
          <Card style={styles.card}>
            <Card.Body style={styles.cardBody}>
              <img
                src="/images/10b83d63-b05c-4e4b-b18d-942e5804e856.jpeg"
                alt="API Documentation"
                style={styles.image}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
  );
}

const styles:any = {
  container: {
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
  row: {
    width: '100%',
  },
  cardBody: {
    padding: '0',
  },
  main: {
    textAlign: 'left' as 'left',
    padding: '20px',
  },
  title: {
    fontSize: '2.5rem',
    margin: '20px 0',
    fontWeight: 'bold',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '20px',
  },
  section: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '1.5rem',
    marginBottom: '10px',
    fontWeight: 'bold',
  },
  list: {
    marginLeft: '20px',
    paddingLeft: '20px',
    fontSize: '1.1rem',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1rem',
    marginTop: '20px',
    backgroundColor: '#007bff',
    borderColor: '#007bff',
    color: '#fff',
  },
  imageContainer: {
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '8px',
  },

}

export default Login



