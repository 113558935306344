import { BrowserRouter as Router} from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { NotFound } from './components/NotFound';
import RouteMain from './Routes';
import { ProjectProvider } from './context/ProjectContext';
import Footer from './components/Pages/Footer';


function App(){
    return (
      <>

        <Router>
        <div >
           <Navigation />
          <main className="main-content">
          <RouteMain/>
          </main>
          <Footer/>
        </div>
      </Router>

    </>
    )

  }

export default App;

