import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';


const styles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '40vh',
  backgroundColor: '#f8f9fa',
  color: '#333',
  textAlign: 'center' as 'center',
};

function GoHome({name, isHome=false}:{name:string, isHome?:boolean}) {
    const history = useHistory();

  return (
     <div style={styles}>
       <p >No {name} Added. </p>
       {!isHome && <Button onClick={()=>history.push('/home')}>Go Home</Button>}
         </div>
  )
}

export default GoHome