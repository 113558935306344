import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CustomTable from "../../../helper/CustomTable";
import { Button, Spinner } from "react-bootstrap";
import { useAuth } from "react-oidc-context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import fetchData from "../../../helper/fetchdata";
import "../../../style/index.css";
import AccessDenied from "../../Pages/AccessDenied";
import { useProjects } from "../../../context/ProjectContext";

interface ModuleType {
  module_id: number;
  module_name: string;
  project_name: string;
  module_api_url: string;
}

function AllModules() {
  const auth = useAuth();
  const data = useProjects();
  const params: { [key: string]: string | undefined } = useParams();
  const history = useHistory();
  const [initialLoading, setInitialLoading] = useState<Boolean>(true);
  const [dataList, setDataList] = useState<ModuleType[]>([]);
  const [accessDenied, setAccessDenied] = useState(3);

  useEffect(() => {
    //console.log(data)
if(data?.initialLoading===1 && data?.isSettings){
   getData(params.id as string)
}
if(data?.initialLoading===1 && !data?.isSettings){
  setAccessDenied(1)
  setInitialLoading(false)
}

  }, [data?.initialLoading])

  //get all the modules of a particular project
  const getData = async (id: string) => {
    try {
      setInitialLoading(true);
      const result = await fetchData(
        `admin/modules/project-id/${id}`,
        "get",
        null,
        String(auth.user?.id_token),
        null
      );
      if (result.responseCode === 200) {
        setDataList(result.response.data);
        setAccessDenied(2);
        setInitialLoading(false);
      } else if (result.responseCode === 403) {
        setAccessDenied(1);
        setInitialLoading(false);
      } else {
          toast.error("Something went wrong", {
          position: "bottom-right",
        });
         //setInitialLoading(false)
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: "bottom-right",
      });
    }
  };

  const headings = ["Name", "Project", "API URL", "Actions"];

  const actionOption = [
    {
      type: 1,
      link: "/module/id",
      linkedField: "module_id",
      caption: "View API Data",
      permission_field: "perm_view",
    },
    {
      type: 2,
      link: "/admin/module/edit",
      linkedField: "module_id",
      caption: "Edit Module",
      permission_field: "perm_update",
    },
    {
      type: 3,
      link: "admin/modules/delete/id",
      linkedField: "module_id",
      caption: "Delete Module",
      permission_field: "perm_delete",
    },
  ];

  const generateRows = (data: ModuleType) => {
    return (
      <>
        <td>{data.module_name}</td>
        <td>{data.project_name}</td>
        <td>{data.module_api_url}</td>
      </>
    );
  };
  return (
      <div className='m-5'>
       {accessDenied===1 && !initialLoading && <AccessDenied url={"/home"} />}
       {initialLoading && <div className="spinner">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div> }
    {accessDenied===2 && !initialLoading && <>
   <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2%' }}>
    <div>
      <Button variant='primary' onClick={()=> history.push(`/admin/module/add/project-id/${params.id}`)}>Add Module</Button>
    </div>
    <div>
      <Button onClick={()=>history.push('/admin')}>Go Back</Button>
    </div>
  </div>
   <ToastContainer />
    <CustomTable headings={headings} generateRows={generateRows} data={dataList} setData={setDataList} routeLinks={actionOption}/>
      </>}
    </div>
  );
}

export default AllModules;
